import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from 'react-formio';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-canvas';
import debounce from 'lodash/debounce';

import settingsForm from './AriesSignature.settingsForm';
import useFormioValue from '../common/useFormioValue';
import { dataURItoBlob } from './dataURIToBlob';

import './style.scss';

const AriesSignature = ({ component, getValue, onChange, onEvent, fileService }) => {
  const [raw, setValue] = useFormioValue(getValue, onChange);
  const value = raw?.value;

  const [imageSrc, setImageSrc] = React.useState();
  const [sigPadDimen, setSigPadDimen] = React.useState({});

  const sigPad = React.useRef();
  const sigPadContainer = React.useRef();

  React.useEffect(() => {
    if (!sigPadContainer.current) {
      return;
    }

    const handler = debounce(() => {
      setSigPadDimen({
        height: sigPadContainer.current.clientHeight,
        width: sigPadContainer.current.clientWidth,
      });
    }, 100);

    let limit = 100;
    const interval = setInterval(() => {
      if (--limit === 0) {
        clearInterval(interval);
        return;
      }

      if (sigPadContainer.current.clientHeight && sigPadContainer.current.clientWidth) {
        clearInterval(interval);
        handler();
      }
    }, 100);

    window.addEventListener('resize', handler);

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', handler);
    };
  }, [sigPadContainer.current]);

  React.useEffect(() => {
    if (!value) {
      setImageSrc();
      return;
    }

    (async () => {
      const { url } = await fileService.downloadFile(value);
      setImageSrc(url);
    })();
  }, [value, fileService]);

  const handleSave = React.useCallback((e) => {
    e.preventDefault();

    if (!sigPad.current) {
      return;
    }

    const b64img = sigPad.current
      .getTrimmedCanvas()
      .toDataURL('image/png');

    const imgBlob = dataURItoBlob(b64img);
    imgBlob.name = `signature-${Date.now()}.png`;

    (async () => {
      const fileInfo = await fileService.uploadFile(
        component.storage,
        imgBlob,
        imgBlob.name,
      );

      onEvent(`set-${component.key}`, { value: fileInfo });
      setValue({ value: fileInfo });
    })();
  }, [component, sigPad.current, fileService]);

  const handleClear = React.useCallback(() => {
    setValue({ value: null });

    if (sigPad.current) {
      sigPad.current.clear();
    }
  }, [sigPad.current]);

  return (
    <div className="col border border-dark py-3 px-4 aries-form-control">
      <label htmlFor={`select-${component.key}`}>{component.label}</label>
      <div className="signature-value-container" ref={sigPadContainer}>
        {value ? (
          <img className="sig-image" src={imageSrc} alt={value.name} />
        ) : (
          <SignaturePad
            canvasProps={{ ...sigPadDimen }}
            ref={sigPad}
          />
        )}
      </div>
      <div className="signature-buttons signature-btn-action">
        <button className='btn btn-secondary mr-2' onClick={handleClear}>
          Clear
        </button>
        <button className='btn btn-primary' onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

AriesSignature.propTypes = {
  component: PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string.isRequired,
    displayAs: PropTypes.string,
    canHide: PropTypes.bool,
    allowNotes: PropTypes.bool,
  }).isRequired,
  getValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onEvent: PropTypes.func,
  fileService: PropTypes.shape({
    uploadFile: PropTypes.func,
  }).isRequired,
};

export default class ReactAriesSignature extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Aries Signature Component',
      icon: 'id-badge',
      group: 'ariesForms',
      documentation: '',
      weight: -10,
      schema: ReactAriesSignature.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'ariessignature',
      hideLabel: true,
      image: true,
      uploadOnly: false,
      isUploadEnabled: true,
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <AriesSignature
        component={this.component} // These are the component settings if you want to use them to render the component.
        getValue={this.getValueAsync} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        onEvent={this.emitCustomEvent} // Emit events using the base component's event emitter
        fileService={this.fileService}
      />,
      element,
    );
  }

  /**
   * Delays the evaluation of this.dataValue because otherwise react cannot
   * get the latest value for the component.
   */
  getValueAsync = () =>
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.dataValue) {
          clearInterval(interval);
          resolve(this.dataValue)
        }
      }, 50);
    });

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  emitCustomEvent = (type, event) => {
    this.emit(this.interpolate(type), this.data);
    this.events.emit(this.interpolate(type), this.data);
    this.emit("customEvent", {
      type: this.interpolate(type),
      component: this.component,
      data: this.data,
      event: event,
    });
  };
}
