import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            type: "textfield",
            input: true,
            key: "customClass",
            label: "Custom CSS Class",
            placeholder: "Custom CSS Class",
            tooltip: "Custom CSS class to add to this component.",
          },
          {
            key: "pageNo",
            type: "number",
            input: true,
            label: "Page number",
            validate: { required: true },
          },
          {
            key: "pageCount",
            type: "number",
            input: true,
            label: "Page count",
            validate: { required: true },
          },
          {
            key: "compact",
            type: "checkbox",
            input: true,
            label: "Compact",
            tooltip:
              "If checked the table will look the way it does in page 2 of AES 2.2",
          },
          {
            key: "pageBreakBefore",
            type: "checkbox",
            input: true,
            label: "Page break before",
            tooltip: "Insert this element at the beginning of a new page",
          },
          {
            key: "label",
            ignore: true,
          },
          {
            key: "labelPosition",
            ignore: true,
          },
          {
            key: "labelWidth",
            ignore: true,
          },
          {
            key: "labelMargin",
            ignore: true,
          },
          {
            key: "placeholder",
            ignore: true,
          },
          {
            key: "description",
            ignore: true,
          },
          {
            key: "tooltip",
            ignore: true,
          },
          {
            key: "tabindex",
            ignore: true,
          },
          {
            key: "hidden",
            ignore: true,
          },
          {
            key: "hideLabel",
            ignore: true,
          },
          {
            key: "autofocus",
            ignore: true,
          },
          {
            key: "dataGridLabel",
            ignore: true,
          },
          {
            key: "disabled",
            ignore: true,
          },
          {
            key: "tableView",
            ignore: true,
          },
          {
            key: "modalEdit",
            ignore: true,
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
      {
        key: "validation",
        ignore: true,
      },
      {
        key: "conditional",
        ignore: true,
      },
      {
        key: "logic",
        ignore: true,
      },
      {
        key: "layout",
        ignore: true,
      },
    ],
    ...extend
  );
};
