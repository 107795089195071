import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveForm, selectError, FormBuilder, Errors } from 'react-formio';
import {push} from 'connected-react-router';
import FormPropertiesEditor, { useFormProperties } from '../../containers/FormPropertiesEditor';

const Create = props => {
  const {
    handleChange,
    saveForm,
    formChange,
    form,
    pdfTemplates,
  } = useFormProperties(props);

  const { options, builder } = props;

  return (
    <div>
      <h2>Create Form</h2>
      <hr />
      <Errors errors={props.errors} />
      <div>
        <FormPropertiesEditor
          {...props}
          form={form}
          pdfTemplates={pdfTemplates}
          handleChange={handleChange}
          saveForm={saveForm}
        />
        <FormBuilder
          key={form._id}
          form={form}
          options={options}
          builder={builder}
          onChange={formChange}
        />
      </div>
    </div>
  );
};

Create.propTypes = {
  errors: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    form: {display: 'form'},
    saveText: 'Create Form',
    errors: selectError('form', state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveForm: (form) => {
      const newForm = {
        ...form,
        tags: ['common'],
      };
      dispatch(saveForm('form', newForm, (err, form) => {
        if (!err) {
          dispatch(push(`/form/${form._id}`));
        }
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Create);
