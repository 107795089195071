import React from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import PropTypes from "prop-types";

import settingsForm from "./AriesFormHeader.settingsForm";
import useFormioValue from "../common/useFormioValue";
import "./style.scss";

const AriesFormHeader = ({ getValue, onChange, onEvent }) => {
  const [raw] = useFormioValue(getValue, onChange);
  const value = raw?.value;

  const emit = React.useCallback(
    (type) => (e) => {
      onEvent(type, e);
    },
    [onEvent]
  );

  return (
    <div className="d-flex flex-column flex-md-row border border-dark py-4 px-2 aries-header">
      <div className="col-md-6 d-flex flex-row align-items-center">
        <img
          height="80"
          width="80"
          className="mr-3"
          src="https://staging.customer.aries4.firelabinc.com/img/img-placeholder.png"
          alt="Building"
        />
        <div className="d-flex flex-column">
          <h2>{value?.propertyInformation?.buildingName ?? "Building"}</h2>
          <div className="d-flex flex-row">
            <div className="icon" onClick={emit("building")}>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAMAAADto6y6AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGNQTFRFTbzpTbzpTbzpAAAATbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpTbzpNmf10gAAACF0Uk5T/+oVAK50l2pAhS5jYu6gXfGTRu9VHRFFoScXP+0TDPOA1TZJvQAAAJRJREFUeJydkdESgiAQRW8BAptBUElpaf//lYmSNGPZ2Hk9s/fu7AKZzZZlsCC4KIBC8JmQTAGKyZnQtANK0tgryuAfjNV9njU4SJswg/hQLgfhvACEdzie/JlV3ldJvDNNLwoXYlSIUaGPCuEV9bU8rXuJ615ZbW29urxJQlM5HvHW0J21RO2v8ulR3UMk+LprjzwBwL8Idt5KIV0AAAAASUVORK5CYII=" />
            </div>
            <div className="icon ml-2" onClick={emit("qr-code")}>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgAQMAAADYVuV7AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAAFhJREFUeAHF0wEGACAMBdDRATpSV+9IHSB+fRoIlN/6TB6sGbOwAI1PBh7DVk6QZwuWCDU11gZz2Df4oAJExT+tXQUuo8ySga0PAAc2cFC2FuDiFsLuNCoDBUdOSNPDC/gAAAAASUVORK5CYII=" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 mt-3 mt-md-0 v-divider">
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="ic-phone mr-3"></div>
          <p className="m-0">Office Number: {value?.propertyInformation?.officeNumber ?? "N/A"}</p>
        </div>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="ic-phone mr-3"></div>
          <p className="m-0">Cell Number: {value?.propertyInformation?.cellNumber ?? "N/A"}</p>
        </div>
        <div className="d-flex flex-row">
          <div className="ic-address mr-3"></div>
          <p className="m-0">Address:</p>
          {value?.propertyInformation?.address ? (
            <div className="col">
              <p className="m-0">{value?.propertyInformation?.buildingName},</p>
              <p className="m-0">{value?.propertyInformation?.address},</p>
              <p className="m-0">
                {value?.propertyInformation?.city}, {value?.propertyInformation?.state},{" "}
                {value?.propertyInformation?.zip}
              </p>
              <a
                href={`https://www.google.com/maps/search/${encodeURI(
                  `${value?.propertyInformation?.buildingName}, ${value?.propertyInformation?.address}, ${value?.propertyInformation?.city}, ${value?.propertyInformation?.state}, ${value?.propertyInformation?.zip}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ic-map"
              >
                <span></span>
              </a>
            </div>
          ) : (
            <p className="ml-1 mb-0">N/A</p>
          )}
        </div>
      </div>
    </div>
  );
};

AriesFormHeader.propTypes = {
  component: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  getValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onEvent: PropTypes.func,
};

export default class ReactAriesFormHeader extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Aries Form Header",
      icon: "star",
      group: "ariesForms",
      documentation: "",
      weight: -10,
      schema: ReactAriesFormHeader.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "ariesformheader",
      hideLabel: true,
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <AriesFormHeader
        component={this.component} // These are the component settings if you want to use them to render the component.
        getValue={this.getValueAsync} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        onEvent={this.emitCustomEvent} // Emit events using the base component's event emitter
      />,
      element
    );
  }

  /**
   * Delays the evaluation of this.dataValue because otherwise react cannot
   * get the latest value for the component.
   */
  getValueAsync = () =>
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.dataValue) {
          clearInterval(interval);
          resolve(this.dataValue)
        }
      }, 50);
    });

  emitCustomEvent = (type, event) => {
    this.emit(this.interpolate(type), this.data);
    this.events.emit(this.interpolate(type), this.data);
    this.emit("customEvent", {
      type: this.interpolate(type),
      component: this.component,
      data: this.data,
      event: event,
    });
  };

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
