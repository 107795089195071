import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            key: "numRows",
            type: "number",
            input: true,
            label: "Number of rows",
            validate: { required: true },
          },
          {
            key: "isAttachment",
            type: "checkbox",
            input: true,
            label: "This table is an attachment",
          },
          {
            key: "attachmentLength",
            type: "number",
            input: true,
            label: "Attachment items",
            placeholder:
              "Number of rows to be included in each attachment table",
            conditional: {
              when: "isAttachment",
              eq: "false",
              show: "true",
            },
            validate: { required: true },
          },
          {
            key: "pageNo",
            type: "number",
            input: true,
            label: "Page number",
            conditional: {
              when: "isAttachment",
              eq: "true",
              show: "true",
            },
            validate: { required: true },
          },
          {
            key: "showHeading",
            type: "checkbox",
            input: true,
            label: "Show heading",
          },
          {
            autofocus: false,
            input: true,
            tree: true,
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Label",
                key: "label",
                type: "textfield",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
                validate: { required: true },
              },
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Column Span",
                key: "colSpan",
                type: "number",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
              },
            ],
            tableView: true,
            label: "Header",
            key: "headerItems",
            type: "datagrid",
            addAnotherPosition: "bottom",
            conditional: {
              when: "showHeading",
              eq: "true",
              show: "true",
            },
          },
          {
            key: "showSubheading",
            type: "checkbox",
            input: true,
            label: "Show subheading",
          },
          {
            autofocus: false,
            input: true,
            tree: true,
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Label",
                key: "label",
                type: "textfield",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
                validate: { required: true },
              },
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Column Span",
                key: "colSpan",
                type: "number",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
              },
            ],
            tableView: true,
            label: "Subheader",
            key: "subheaderItems",
            type: "datagrid",
            addAnotherPosition: "bottom",
            conditional: {
              when: "showSubheading",
              eq: "true",
              show: "true",
            },
          },
          {
            key: "showFooter",
            type: "checkbox",
            input: true,
            label: "Show footer",
          },
          {
            autofocus: false,
            input: true,
            tree: true,
            components: [
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Label",
                key: "label",
                type: "textfield",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
                validate: { required: true },
              },
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Column Span",
                key: "colSpan",
                type: "number",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
              },
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Show overflow indicator",
                tooltip:
                  "If checked will display a checkbox to the left of the label which will be checked if the list being displayed is larger than the allowed number of rows",
                key: "showOverflow",
                type: "checkbox",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
              },
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Show overflow attachment number",
                tooltip:
                  "If checked will display the number of tables that will be attached to contain the overflow",
                key: "showAttachmentNo",
                type: "checkbox",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
              },
            ],
            tableView: true,
            label: "Footer",
            key: "footerItems",
            type: "datagrid",
            addAnotherPosition: "bottom",
            conditional: {
              when: "showFooter",
              eq: "true",
              show: "true",
            },
          },
          {
            type: "textfield",
            input: true,
            key: "customClass",
            label: "Custom CSS Class",
            placeholder: "Custom CSS Class",
            tooltip: "Custom CSS class to add to this component.",
          },
          {
            key: "label",
            ignore: true,
          },
          {
            key: "labelPosition",
            ignore: true,
          },
          {
            key: "labelWidth",
            ignore: true,
          },
          {
            key: "labelMargin",
            ignore: true,
          },
          {
            key: "placeholder",
            ignore: true,
          },
          {
            key: "description",
            ignore: true,
          },
          {
            key: "tooltip",
            ignore: true,
          },
          {
            key: "tabindex",
            ignore: true,
          },
          {
            key: "hidden",
            ignore: true,
          },
          {
            key: "hideLabel",
            ignore: true,
          },
          {
            key: "autofocus",
            ignore: true,
          },
          {
            key: "dataGridLabel",
            ignore: true,
          },
          {
            key: "disabled",
            ignore: true,
          },
          {
            key: "tableView",
            ignore: true,
          },
          {
            key: "modalEdit",
            ignore: true,
          },
        ],
      },
      {
        key: "data",
        components: [
          {
            autofocus: false,
            input: true,
            tree: true,
            components: [
              {
                autofocus: false,
                input: true,
                label: "Column label",
                key: "columnLabel",
                type: "textfield",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
                validate: { required: true },
              },
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Column width (%)",
                key: "columnWidth",
                type: "number",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
                validate: { required: true },
              },
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Key",
                key: "key",
                type: "textfield",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
                validate: { required: true },
              },
              {
                autofocus: false,
                input: true,
                tableView: true,
                label: "Custom CSS",
                key: "customStyle",
                type: "textfield",
                inDataGrid: true,
                labelPosition: "top",
                inputFormat: "plain",
              },
            ],
            tableView: true,
            label: "Column header row",
            key: "columns",
            type: "datagrid",
            addAnotherPosition: "bottom",
          },
          {
            key: "multiple",
            ignore: true,
          },
          {
            key: "defaultValue",
            ignore: true,
          },
          {
            key: "persistent",
            ignore: true,
          },
          {
            key: "protected",
            ignore: true,
          },
          {
            key: "dbIndex",
            ignore: true,
          },
          {
            key: "encrypted",
            ignore: true,
          },
          {
            key: "redrawOn",
            ignore: true,
          },
          {
            key: "clearOnHide",
            ignore: true,
          },
          {
            key: "calculateServer",
            ignore: true,
          },
          {
            key: "allowCalculateOverride",
            ignore: true,
          },
        ],
      },
      {
        key: "validation",
        ignore: true,
      },
      {
        key: "conditional",
        ignore: true,
      },
      {
        key: "logic",
        ignore: true,
      },
      {
        key: "layout",
        ignore: true,
      },
    ],
    ...extend
  );
};
