import baseEditForm from 'formiojs/components/_classes/component/Component.form';
import {conditional, data, display, ignoreSections, validation} from '../common/base.settingsForm';

export default (...extend) => {
  return baseEditForm([
    {
      key: 'display',
      components: [
        ...display.filter(ctrl => ctrl.key !== 'label'),
        {
          key: 'label',
          type: 'textfield',
          input: true,
          label: 'Label',
        },
        {
          type: 'textfield',
          input: true,
          key: 'customClass',
          label: 'Custom CSS Class',
          placeholder: 'Custom CSS Class',
          tooltip: 'Custom CSS class to add to this component.',
        },
        {
          type: 'select',
          input: true,
          key: 'displayAs',
          label: 'Display as',
          tooltip: 'TIP: Use radio buttons when you have 3 or fewer options',
          defaultValue: 'radio',
          dataSrc: 'values',
          data: {
            values: [
              {
                label: 'Radio button',
                value: 'radio',
              },
              {
                label: 'Dropdown',
                value: 'dropdown',
              },
            ],
          },
        },
        {
          key: 'canHide',
          input: true,
          type: 'checkbox',
          label: 'Allow this question to be hidden in the resulting report',
        },
      ],
    },
    {
      key: 'data',
      components: [
        ...data,
        {
          "autofocus": false,
          "input": true,
          "tree": true,
          reorder: true,
          tooltip: 'The radio button values that can be picked for this field. Values are text submitted with the form data. Labels are text that appears next to the radio buttons on the form.',
          defaultValue: [{ label: '', value: '', generatesDeficiency: false }],
          "components": [
            {
              label: 'Label',
              key: 'label',
              input: true,
              type: 'textfield',
            },
            {
              label: 'Value',
              key: 'value',
              input: true,
              type: 'textfield',
              allowCalculateOverride: true,
              calculateValue: { _camelCase: [{ var: 'row.label' }] },
              validate: {
                required: true,
              },
            },
            {
              label: 'Generates deficiency',
              key: 'generatesDeficiency',
              input: true,
              type: 'checkbox',
              defaultValue: false,
            },
          ],
          "tableView": true,
          "label": "Values",
          "key": "values",
          "type": "datagrid",
          "addAnotherPosition": "bottom",
        },
        {
          key: 'allowNotes',
          label: 'Allows notes',
          type: 'checkbox',
          input: true,
        },
      ],
    },
    {
      key: 'conditional',
      components: conditional,
    },
    {
      key: 'validation',
      components: validation,
    },
    ignoreSections.ignoreLayout,
    ignoreSections.ignoreLogic,
  ], ...extend);
};
