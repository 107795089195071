import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from 'react-formio';
import PropTypes from 'prop-types';

import settingsForm from './AriesMultipleChoice.settingsForm';
import Checkbox from '../common/Checkbox';
import NotesInput from '../common/NotesInput';
import NotesToggle from '../common/NotesToggle';
import RadioButton from '../common/RadioButton';
import useFocus from '../common/useFocus';
import useFormioValue from '../common/useFormioValue';
import useHide from "../common/useHide";
import useNotes from '../common/useNotes';
import useDeficiency from './useDeficiency';

import './style.scss';

const AriesMultipleChoice = ({ component, getValue, onChange }) => {
  const [value, setValue] = useFormioValue(getValue, onChange);
  const [focused, onFocus, onBlur] = useFocus();
  const [isHidden, onToggleHidden] = useHide(component, value, setValue);
  const [hasNotes, onToggleNotes, onChangeNotes] = useNotes(component, value, setValue);
  const [isDeficiency, onChangeDeficiencyNote] = useDeficiency(component, value, setValue);

  const onValueChange = React.useCallback((e) => {
    setValue({ value: e.target.value });
  }, [setValue]);

  return (
    <div className="col border border-dark py-3 px-4 aries-form-control">
      <div className="d-flex flex-row justify-content-between mb-3">
        <label htmlFor={`select-${component.key}`} className={focused ? 'active-label' : ''}>{component.label}</label>
        <div className="d-flex flex-row justify-content-start">
          <NotesToggle
            id={`notes-${component.key}`}
            name="notes"
            label="Notes"
            visible={component.allowNotes}
            className="mr-3"
            checked={hasNotes}
            onChange={onToggleNotes}
          />
          {
            component.canHide &&
            <Checkbox
              id={`hide-${component.key}`}
              name="hide"
              label="Hide"
              checked={isHidden}
              onChange={onToggleHidden}
            />
          }
        </div>
      </div>


      {component.displayAs === 'dropdown' ? (
        <select name={`select-${component.key}`} className="custom-select" onFocus={onFocus} onBlur={onBlur}>
          {component.values?.map(({ label, value: val }, idx) => (
            <option key={idx} value={val}>{label}</option>
          ))}
        </select>
      ) : (
        <div className="form-group">
          {component.values?.map(({ label, value: val }, idx) => (
            <RadioButton
              key={idx}
              id={`opt-${val}`}
              name={val}
              label={label}
              value={val}
              checked={value?.value === val}
              onChange={onValueChange}
            />
          ))}
        </div>
      )}

      <NotesInput
        visible={hasNotes}
        id={`notes-${component.key}`}
        value={value?.notes ?? ''}
        onChange={onChangeNotes}
      />

      <NotesInput
        visible={isDeficiency}
        id={`deficiency-note-${component.key}`}
        value={value?.deficiencyNote}
        onChange={onChangeDeficiencyNote}
      />
    </div>
  );
};

AriesMultipleChoice.propTypes = {
  component: PropTypes.shape({
    label: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    key: PropTypes.string.isRequired,
    displayAs: PropTypes.string,
    canHide: PropTypes.bool,
    allowNotes: PropTypes.bool,
  }).isRequired,
  getValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default class ReactAriesMultipleChoice extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'Aries Multiple Choice',
      icon: 'question',
      group: 'ariesForms',
      documentation: '',
      weight: -10,
      schema: ReactAriesMultipleChoice.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'ariesmultiplechoice',
      values: [],
      hideLabel: true,
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <AriesMultipleChoice
        component={this.component} // These are the component settings if you want to use them to render the component.
        getValue={this.getValueAsync} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element,
    );
  }

  /**
   * Delays the evaluation of this.dataValue because otherwise react cannot
   * get the latest value for the component.
   */
  getValueAsync = () =>
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.dataValue) {
          clearInterval(interval);
          resolve(this.dataValue)
        }
      }, 50);
    });

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
