import React from 'react';
import PropTypes from 'prop-types';

const PdfPropertiesEditor = (props) => {
  const { handleChange, form } = props;

  if (form?.ariesType !== 'pdf') {
    return null;
  }

  return (
    <div className="row">
      <div className="col-lg-2 col-md-4 col-sm-4 pb-2">
        <div id="form-group-title" className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="pdfAbsDimen"
            checked={form.pdfAbsDimen}
            onChange={event => handleChange('pdfAbsDimen', event)}
          />
          <label htmlFor="pdfAbsDimen" className="form-check-label field-required">Enable absolute sizing</label>
        </div>
      </div>
      {form.pdfAbsDimen ? (
        <>
          <div className="col-lg-2 col-md-4 col-sm-4">
            <div id="form-group-title" className="form-group">
              <label htmlFor="ariesPdfHeight" className="control-label field-required">Pdf height</label>
              <input
                type="number"
                className="form-control"
                id="ariesPdfHeight"
                value={form.ariesPdfHeight}
                onChange={event => handleChange('ariesPdfHeight', { ...event, target: { ...event.target, value: +event.target.value } })}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-4">
            <div id="form-group-title" className="form-group">
              <label htmlFor="ariesPdfWidth" className="control-label field-required">Pdf width</label>
              <input
                type="number"
                className="form-control"
                id="ariesPdfWidth"
                value={form.ariesPdfWidth}
                onChange={event => handleChange('ariesPdfWidth', { ...event, target: { ...event.target, value: +event.target.value } })}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

PdfPropertiesEditor.propTypes = {
  form: PropTypes.object.isRequired,
  pdfTemplates: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
  handleChange: PropTypes.func,
  saveForm: PropTypes.func,
};

PdfPropertiesEditor.defaultProps = {
  form: {
    title: '',
    name: '',
    path: '',
    display: 'form',
    type: 'form',
    components: [],
    ariesType: 'form',
  },
  pdfTemplates: [],
};

export default PdfPropertiesEditor;
