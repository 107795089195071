import React from 'react';

const useFormioValue = (getValue, onChange) => {
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    let cancelFn;
    const cancel = new Promise(resolve => cancelFn = resolve);
    
    Promise.race([
      getValue().then(setValue),
      cancel,
    ]);

    return () => cancelFn();
  }, [getValue]);

  const broadcastChange = React.useCallback((nextValue) => {
    const updatedAt = nextValue.value ? new Date() : value?.updatedAt;
    const merged = { ...value, ...nextValue, updatedAt };

    setValue(merged);
    onChange(merged);
  }, [value, onChange]);

  return [value, broadcastChange];
};

export default useFormioValue;
