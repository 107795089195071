import baseEditForm from "formiojs/components/_classes/component/Component.form";
import { ignoreSections } from "../common/base.settingsForm";

export default (...extend) => {
  return baseEditForm(
    [
      ignoreSections.ignoreConditional,
      ignoreSections.ignoreData,
      ignoreSections.ignoreDisplay,
      ignoreSections.ignoreLayout,
      ignoreSections.ignoreLogic,
      ignoreSections.ignoreValidation,
    ],
    ...extend
  );
};
