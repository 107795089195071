import React from 'react';
import PropTypes from 'prop-types';
import { getNames } from 'country-list';
import states from 'states-us';

import InputFormPropertiesEditor from './InputFormPropertiesEditor';
import PdfPropertiesEditor from './PdfPropertiesEditor';

const FormPropertiesEditor = (props) => {
  const { saveText, handleChange, saveForm, form, pdfTemplates } = props;

  return (
    <>
      <div className="row">
        <div className="col-lg-2 col-md-4 col-sm-4">
          <div id="form-group-title" className="form-group">
            <label htmlFor="title" className="control-label field-required">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="Enter the form title"
              value={form.title ?? ''}
              onChange={event => handleChange('title', event)}
            />
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-3">
          <div id="form-group-aries-type" className="form-group">
            <label htmlFor="form-aries-type" className="control-label">Type</label>
            <div className="input-group">
              <select
                className="form-control"
                name="form-aries-type"
                id="form-aries-type"
                value={form.ariesType}
                onChange={event => handleChange('ariesType', event)}
              >
                <option label="Form" value="form">Form</option>
                <option label="PDF" value="pdf">PDF</option>
              </select>
            </div>
          </div>
        </div>
        {
          form?.ariesType !== 'pdf' ? (
            <>
              {pdfTemplates?.length ? (
                <div className="col-lg-2 col-md-3 col-sm-3">
                  <div id="form-group-aries-pdf-id" className="form-group">
                    <label htmlFor="form-aries-pdf-id" className="control-label">Associated PDF</label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        name="form-aries-pdf-id"
                        id="form-aries-pdf-id"
                        value={form.ariesPdfId}
                        onChange={event => handleChange('ariesPdfId', event)}
                      >
                        <option label="Select associated pdf" value="">Select associated pdf</option>
                        {pdfTemplates.map(f => (
                          <option key={f._id} label={f.title} value={f._id}>{f.title}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* <div className="col-lg-2 col-md-3 col-sm-3">
                <div id="form-group-aries-minimum-plan" className="form-group">
                  <label htmlFor="form-aries-minimum-plan" className="control-label">Available for</label>
                  <div className="input-group">
                    <select
                      className="form-control"
                      name="form-aries-minimum-plan"
                      id="form-aries-minimum-plan"
                      value={form.ariesMinimumPlan}
                      onChange={event => handleChange('ariesMinimumPlan', event)}
                    >
                      <option label="All plans" value="">All plans</option>
                      <option label="Spark" value="Spark">Spark</option>
                      <option label="Flame" value="Flame">Flame</option>
                      <option label="Blaze" value="Blaze">Blaze</option>
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-2 col-md-3 col-sm-3">
                <div id="form-group-aries-country" className="form-group">
                  <label htmlFor="form-aries-country" className="control-label">Country</label>
                  <div className="input-group">
                    <select
                      className="form-control"
                      name="form-aries-country"
                      id="form-aries-country"
                      value={form.ariesCountry}
                      onChange={event => handleChange('ariesCountry', event)}
                    >
                      <option label="Any country" value="">Any country</option>
                      {getNames().map(country => (
                        <option label={country} value={country}>{country}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {form.ariesCountry === 'United States of America' ? (
                <div className="col-lg-2 col-md-3 col-sm-3">
                  <div id="form-group-aries-state" className="form-group">
                    <label htmlFor="form-aries-state" className="control-label">State</label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        name="form-aries-state"
                        id="form-aries-state"
                        value={form.ariesState}
                        onChange={event => handleChange('ariesState', event)}
                      >
                        <option label="Any state" value="">Any state</option>
                        {states.map(({ name, abbreviation }) => (
                          <option label={name} value={abbreviation}>{name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : null
        }
        <div id="save-buttons" className={`col-lg-${form.ariesType !== 'pdf' ? (form.ariesCountry === 'United States of America' ? '2' : '4') : '8'} col-md-5 col-sm-5 save-buttons pull-right`}>
          <div className="form-group pull-right">
            <span className="btn btn-primary" onClick={() => saveForm()}>
              {saveText}
            </span>
          </div>
        </div>
      </div>
      <InputFormPropertiesEditor {...props} />
      <PdfPropertiesEditor {...props} />
    </>
  );
};

FormPropertiesEditor.propTypes = {
  form: PropTypes.object.isRequired,
  pdfTemplates: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
  handleChange: PropTypes.func,
  saveForm: PropTypes.func,
};

FormPropertiesEditor.defaultProps = {
  form: {
    title: '',
    name: '',
    path: '',
    display: 'form',
    type: 'form',
    components: [],
    ariesType: 'form',
  },
  pdfTemplates: [],
};

export default FormPropertiesEditor;