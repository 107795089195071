import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Route } from 'react-router-dom';

import Header from './containers/Header';
import Footer from './containers/Footer';
import Form from './views/Form';
import Auth from './views/Auth/Auth';

const App = () => {
  const history = useHistory();
  const isAuthenticated = useSelector(state => state.auth.authenticated)

  useEffect(() => {
    if (!isAuthenticated) {
      history.replace('/auth');
    } else {
      history.replace('/form');
    }
  }, [history, isAuthenticated]);

  return (
    <div>
      <Header />

      <div className="container" id="main">
        <Route path="/form" component={Form} />
        <Route path="/auth" component={Auth} />
      </div>

      <Footer />
    </div>
  );
};

export default App;
