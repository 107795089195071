import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from 'react-formio';
import PropTypes from 'prop-types';

import settingsForm from './CaliforniaTextBox.settingsForm';
import useFormioValue from '../common/useFormioValue';

import './style.scss';

const CaliforniaTextBox = ({ getValue, onChange }) => {
  const [raw] = useFormioValue(getValue, onChange);
  const value = raw?.value;

  return (
    <div className="col border border-dark py-3 px-4 text-center">
      <p className="mb-0"><strong><em>{value}</em></strong></p>
    </div>
  );
};

CaliforniaTextBox.propTypes = {
  component: PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string.isRequired,
    displayAs: PropTypes.string,
    canHide: PropTypes.bool,
    allowNotes: PropTypes.bool,
  }).isRequired,
  getValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onEvent: PropTypes.func,
};

export default class CaliforniaTextBoxComponent extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'California Text Box',
      icon: 'font',
      group: 'california',
      documentation: '',
      weight: -10,
      schema: CaliforniaTextBoxComponent.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: 'californiatextbox',
      hideLabel: true,
      input: false,
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <CaliforniaTextBox
        component={this.component} // These are the component settings if you want to use them to render the component.
        getValue={this.getValueAsync} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        onEvent={this.emitCustomEvent} // Emit events using the base component's event emitter
      />,
      element,
    );
  }

  /**
   * Delays the evaluation of this.dataValue because otherwise react cannot
   * get the latest value for the component.
   */
  getValueAsync = () =>
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.dataValue) {
          clearInterval(interval);
          resolve(this.dataValue)
        }
      }, 50);
    });

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  emitCustomEvent = (type, event) => {
    this.emit(this.interpolate(type), this.data);
    this.events.emit(this.interpolate(type), this.data);
    this.emit("customEvent", {
      type: this.interpolate(type),
      component: this.component,
      data: this.data,
      event: event,
    });
  };
}
