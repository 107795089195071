import { getContextComponents } from 'formiojs/utils/utils';

export const ignoreSections = {
  ignoreDisplay: {
    key: 'display',
    ignore: true,
  },
  ignoreData: {
    key: 'data',
    ignore: true,
  },
  ignoreValidation: {
    key: 'validation',
    ignore: true,
  },
  ignoreApi: {
    key: 'api',
    ignore: true,
  },
  ignoreConditional: {
    key: 'conditional',
    ignore: true,
  },
  ignoreLogic: {
    key: 'logic',
    ignore: true,
  },
  ignoreLayout: {
    key: 'layout',
    ignore: true,
  },
};

export const display = [
  {
    key: 'label',
    ignore: true,
  }, {
    key: 'labelPosition',
    ignore: true,
  }, {
    key: 'labelWidth',
    ignore: true,
  }, {
    key: 'labelMargin',
    ignore: true,
  }, {
    key: 'placeholder',
    ignore: true,
  }, {
    key: 'description',
    ignore: true,
  }, {
    key: 'tooltip',
    ignore: true,
  }, {
    key: 'tabindex',
    ignore: true,
  }, {
    key: 'hidden',
    ignore: true,
  }, {
    key: 'hideLabel',
    ignore: true,
  }, {
    key: 'autofocus',
    ignore: true,
  }, {
    key: 'dataGridLabel',
    ignore: true,
  }, {
    key: 'disabled',
    ignore: true,
  }, {
    key: 'tableView',
    ignore: true,
  }, {
    key: 'modalEdit',
    ignore: true,
  },
];

export const data = [
  {
    key: 'multiple',
    ignore: true,
  }, {
    key: 'defaultValue',
    ignore: true,
  }, {
    key: 'persistent',
    ignore: true,
  }, {
    key: 'protected',
    ignore: true,
  }, {
    key: 'dbIndex',
    ignore: true,
  }, {
    key: 'encrypted',
    ignore: true,
  }, {
    key: 'redrawOn',
    ignore: true,
  }, {
    key: 'clearOnHide',
    ignore: true,
  }, {
    key: 'calculateServer',
    ignore: true,
  }, {
    key: 'allowCalculateOverride',
    ignore: true,
  },
];

export const conditional = [
  {
    type: 'panel',
    title: 'Simple',
    key: 'simple-conditional',
    theme: 'default',
    components: [
      {
        type: 'select',
        input: true,
        label: 'This component should Display:',
        key: 'conditional.show',
        dataSrc: 'values',
        data: {
          values: [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ],
        },
      },
      {
        type: 'select',
        input: true,
        label: 'When the form component:',
        key: 'conditional.when',
        dataSrc: 'custom',
        valueProperty: 'value',
        data: {
          custom(context) {
            return getContextComponents(context);
          },
        },
      },
      {
        type: 'textfield',
        input: true,
        label: 'Has the value:',
        key: 'conditional.eq',
      },
      {
        type: 'hidden',
        key: 'customConditional',
        input: false,
        clearOnHide: false,
        calculateValue: `
          value = "show = (component.conditional.show === null) || ((component.conditional.show === true) && ((data[component.conditional.when] ? data[component.conditional.when].value : null) === component.conditional.eq))";
        `,
      },
    ],
  },
  {
    key: 'customConditionalPanel',
    ignore: true,
  },
];

export const validation = [
  {
    weight: 10,
    type: 'checkbox',
    label: 'Required',
    tooltip: 'A required field must be filled in before the form can be submitted.',
    key: 'validate.required',
    input: true
  },
  {
    key: 'unique',
    ignore: true,
  },
  {
    weight: 0,
    type: 'select',
    key: 'validateOn',
    defaultValue: 'change',
    input: true,
    label: 'Validate On',
    tooltip: 'Determines when this component should trigger front-end validation.',
    dataSrc: 'values',
    data: {
      values: [
        { label: 'Change', value: 'change' },
        { label: 'Blur', value: 'blur' }
      ]
    }
  },
  {
    weight: 190,
    type: 'textfield',
    input: true,
    key: 'errorLabel',
    label: 'Error Label',
    placeholder: 'Error Label',
    tooltip: 'The label for this field when an error occurs.'
  },
  {
    weight: 200,
    key: 'validate.customMessage',
    label: 'Custom Error Message',
    placeholder: 'Custom Error Message',
    type: 'textfield',
    tooltip: 'Error message displayed if any error occurred.',
    input: true
  },
  {
    key: 'custom-validation-js',
    ignore: true,
  },
  {
    key: 'json-validation-json',
    ignore: true,
  },
];
