import editGridConfig from 'formiojs/components/editgrid/EditGrid.form';
import { ignoreSections } from '../common/base.settingsForm';

export default (...extend) => {
  return editGridConfig([
    {
      key: 'data',
      components: [
        {
          key: 'defaultValue',
          "autofocus": false,
          "input": true,
          "tree": true,
          tooltip: 'Use this if there are values that the user should not have to enter, for instance, if there are many questions that make up a table put them here',
          defaultValue: [],
          "components": { var: 'component.components' },
          "type": "arieseditgrid",
          "addAnotherPosition": "bottom",
          ignore: false,
          disableAddingRemovingRows: false,
          multiple: true,
        },
        {
          key: 'inlineEdit',
          ignore: true,
        },
        {
          key: 'persistent',
          ignore: true,
        },
        {
          key: 'protected',
          ignore: true,
        },
        {
          key: 'dbIndex',
          ignore: true,
        },
        {
          key: 'encrypted',
          ignore: true,
        },
        {
          key: 'redrawOn',
          ignore: true,
        },
        {
          key: 'clearOnHide',
          ignore: true,
        },
        {
          key: 'customDefaultValue',
          ignore: true,
        },
        {
          key: 'calculateValue',
          ignore: true,
        },
        {
          key: 'calculateServer',
          ignore: true,
        },
        {
          key: 'allowCalculateOverride',
          ignore: true,
        },
      ],
    },
    ignoreSections.ignoreValidation,
    ignoreSections.ignoreLayout,
    ignoreSections.ignoreLogic,
  ], ...extend);
};

