import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { ReactComponent } from "react-formio";
import formatValue from '../common/formatValue';

import { range } from "../../util/range";
import settingsForm from "./CaliforniaTable.settingsForm";
import useFormioValue from "../common/useFormioValue";
import "./style.scss";

const CaliforniaTable = ({ component, getValue }) => {
  const [value] = useFormioValue(getValue);

  const parseStyle = React.useCallback((customStyle) => {
    try {
      return JSON.parse(customStyle);
    } catch {
      return {};
    }
  }, []);

  const offset = React.useMemo(() => {
    if (component.isAttachment) {
      component.attachmentLength = component.numRows;
    }

    return ((component.pageNo ?? 1) - 1) * component.attachmentLength;
  }, [component]);

  const moreThanFive = React.useMemo(() => {
    return (value?.length ?? 0) > 5;
  }, [value]);

  const extraFormCount = React.useMemo(() => {
    if (component.isAttachment) {
      component.attachmentLength = component.numRows;
    }

    const extraFormCount = Math.ceil(
      ((value?.length ?? 0) - component.numRows) / component.attachmentLength
    );
    return extraFormCount > 0 ? extraFormCount : 0;
  }, [component, value]);

  return (
    <table border="1" className={`california-table ${component.customClass}`}>
      {component.showHeading && component.headerItems ? (
        <thead>
          <tr>
            {component.headerItems.map(({ label, colSpan = 1 }, idx) => (
              <th key={label} colSpan={colSpan}>
                {label?.split("%br%").map((line) => (
                  <React.Fragment key={idx}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </th>
            ))}
          </tr>
        </thead>
      ) : (
        <></>
      )}

      <tbody>
        {component.showSubheading ? (
          <tr className="subheading">
            {component.subheaderItems.map(({ label, colSpan = 1 }, idx) => (
              <td key={idx} colSpan={colSpan}>
                {label}
              </td>
            ))}
          </tr>
        ) : (
          <></>
        )}

        <tr className="col-head">
          {component.columns?.map(({ columnLabel, columnWidth }, idx) => (
            <th key={idx} style={{ width: `${columnWidth}%` }}>
              {columnLabel}
            </th>
          )) ?? <></>}
        </tr>

        {value?.slice(offset, component.numRows).map((val, idx) => (
          <tr key={idx}>
            {component.columns?.map(({ key, customStyle }) => (
              <td key={key} style={parseStyle(customStyle)}>
                {formatValue(val[key]?.value ?? val[key])}
              </td>
            ))}
          </tr>
        )) ?? <></>}

        {Array.from(range(component.numRows, value?.length ?? 0)).map(
          (_, idx) => (
            <tr key={idx}>
              {component.columns?.map((_, idx) => (
                <td key={idx}>&nbsp;</td>
              ))}
            </tr>
          )
        )}

        {component.showFooter ? (
          <tr>
            {component.footerItems.map(
              ({ label, colSpan = 1, showOverflow, showAttachmentNo }, idx) => (
                <td key={idx} colSpan={colSpan}>
                  {showOverflow && (
                    <input
                      id="moreThanFive"
                      type="checkbox"
                      checked={moreThanFive}
                      readOnly
                    />
                  )}
                  {label}
                  {showAttachmentNo && (extraFormCount || "")}
                </td>
              )
            )}
          </tr>
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
};

CaliforniaTable.propTypes = {
  component: PropTypes.shape({
    customClass: PropTypes.string,
    pageNo: PropTypes.number,
    isAttachment: PropTypes.bool,
    numRows: PropTypes.number.isRequired,
    attachmentLength: PropTypes.number,
    headerItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        colSpan: PropTypes.number,
      })
    ),
    showSubheading: PropTypes.bool,
    subheaderItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        colSpan: PropTypes.number,
      })
    ),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        customStyle: PropTypes.string,
      })
    ).isRequired,
    showFooter: PropTypes.bool,
    footerItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        colSpan: PropTypes.number,
        showOverflow: PropTypes.bool,
        showAttachmentNo: PropTypes.bool,
      })
    ).isRequired,
  }).isRequired,
  getValue: PropTypes.func,
};

export default class ReactCaliforniaTable extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "California Table",
      icon: "table",
      group: "california",
      documentation: "",
      weight: -10,
      schema: ReactCaliforniaTable.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "californiatable",
      numRows: 5,
      showHeading: true,
      headerItems: [],
      showSubheading: false,
      subheaderItems: [],
      columns: [],
      showFooter: false,
      footerItems: [],
      input: false,
      multiple: true, // required to use an array as value
      validate: {
        required: false,
        custom: '',
        customPrivate: false,
        strictDateValidation: false,
        multiple: true, // required to use an array as value
        unique: false
      },
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <CaliforniaTable
        component={this.component} // These are the component settings if you want to use them to render the component.
        getValue={this.getValueAsync} // The starting value of the component.
      />,
      element
    );
  }

  /**
   * Delays the evaluation of this.dataValue because otherwise react cannot
   * get the latest value for the component.
   */
  getValueAsync = () =>
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.dataValue) {
          clearInterval(interval);
          resolve(this.dataValue)
        }
      }, 50);
    });

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
