import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { ReactComponent } from "react-formio";
import formatDate from 'date-fns/format';

import settingsForm from "./CaliforniaSignatures.settingsForm";
import useFormioValue from "../common/useFormioValue";
import "./style.scss";

const CaliforniaSignatures = ({ component, getValue, fileService }) => {
  const [raw] = useFormioValue(getValue);
  const value = raw?.value;

  const [imageSrc, setImageSrc] = React.useState();

  React.useEffect(() => {
    if (!value) {
      setImageSrc();
      return;
    }

    (async () => {
      console.log('downloading signature image', JSON.stringify(value?.signature));
      const { url } = await fileService.downloadFile(value?.signature);
      console.log('downloaded signature image', url);
      setImageSrc(url);
    })();
  }, [value, fileService]);

  return (
    <table border="1" className={`california-table ${component.customClass}`}>
      <tbody>
        <tr className="subheading">
          <td colSpan={5}>
            Check box if Annual Inspection, Testing &amp; Maintenance Items are Completed in the Indicated Quarter
          </td>
        </tr>

        <tr className="col-head">
          <th>Quarter</th>
          <th>1st - <input type="checkbox" checked /> Annual</th>
          <th>2nd - <input type="checkbox" /> Annual</th>
          <th>3rd - <input type="checkbox" /> Annual</th>
          <th>4th - <input type="checkbox" /> Annual</th>
        </tr>

        <tr>
          <td>Date</td>
          <td>{value ? formatDate(new Date(value?.date), 'Pp') : null}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Print Name</td>
          <td>{value?.printName}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Signature</td>
          <td><img className="signature" src={imageSrc} /></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

CaliforniaSignatures.propTypes = {
  component: PropTypes.shape({}),
  getValue: PropTypes.func,
  fileService: PropTypes.object,
};

export default class ReactCaliforniaSignatures extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "California Signatures",
      icon: "id-badge",
      group: "california",
      documentation: "",
      weight: -10,
      schema: ReactCaliforniaSignatures.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "californiasignatures",
      input: false,
      multiple: true, // required to use an array as value
      validate: {
        required: false,
        custom: '',
        customPrivate: false,
        strictDateValidation: false,
        multiple: true, // required to use an array as value
        unique: false
      },
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <CaliforniaSignatures
        component={this.component} // These are the component settings if you want to use them to render the component.
        getValue={this.getValueAsync} // The starting value of the component.
        fileService={this.fileService}
      />,
      element
    );
  }

  /**
   * Delays the evaluation of this.dataValue because otherwise react cannot
   * get the latest value for the component.
   */
  getValueAsync = () =>
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.dataValue) {
          clearInterval(interval);
          resolve(this.dataValue)
        }
      }, 50);
    });

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
