import { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectForms, indexForms } from 'react-formio';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _camelCase from 'lodash/camelCase';

const reducer = (form, { type, value }) => {
  const formCopy = _cloneDeep(form);
  switch (type) {
    case 'formChange':
      for (let prop in value) {
        // eslint-disable-next-line no-prototype-builtins
        if (value.hasOwnProperty(prop)) {
          form[prop] = value[prop];
        }
      }
      return form;
    case 'replaceForm':
      return _cloneDeep(value);
    case 'title':
      if (type === 'title' && !form._id) {
        formCopy.name = _camelCase(value);
        formCopy.path = _camelCase(value).toLowerCase();
      }
      break;
    default:
      break;
  }
  _set(formCopy, type, value);
  return formCopy;
};

const useFormProperties = (props) => {
  const dispatch = useDispatch();

  const [form, dispatchFormAction] = useReducer(reducer, _cloneDeep(props.form));
  const [customerFilterEnabled, setCustomerFilterEnabled] = useState(form.ariesCustomerEmails?.length > 0);
  const [pdfAbsDimen, setPdfAbsDimen] = useState(!!form.ariesPdfHeight && !!form.ariesPdfWidth);
  const pdfTemplates = useSelector(state => selectForms('forms', state));

  useEffect(() => {
    dispatch(indexForms('forms', 1, { ariesType: 'pdf' }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { form: newForm } = props;
    if (newForm && (form._id !== newForm._id || form.modified !== newForm.modified)) {
      if (!newForm.ariesType) {
        newForm.ariesType = 'form';
      }

      dispatchFormAction({ type: 'replaceForm', value: newForm });
    }
    // eslint-disable-next-line
  }, [props.form]);

  const saveForm = useCallback(() => {
    const { saveForm } = props;
    if (saveForm && typeof saveForm === 'function') {
      saveForm(form);
    }
  }, [props, form]);

  const handleChange = useCallback((path, event) => {
    const { target } = event;

    switch (path) {
      case 'customerFilterEnabled':
        setCustomerFilterEnabled(target.checked);
        dispatchFormAction({ type: 'ariesCustomerEmails', value: [] });
        break;
      case 'pdfAbsDimen':
        setPdfAbsDimen(target.checked);
        dispatchFormAction({ type: 'ariesPdfHeight', value: 800 });
        dispatchFormAction({ type: 'ariesPdfWidth', value: 600 });
        break;
      default: {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        dispatchFormAction({ type: path, value });
        break;
      }
    }
  }, []);

  const formChange = useCallback((newForm) => dispatchFormAction({ type: 'formChange', value: newForm }), []);

  return {
    form: {...form, customerFilterEnabled, pdfAbsDimen},
    pdfTemplates,
    handleChange,
    saveForm,
    formChange,
  };
};

export default useFormProperties;
