import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { indexForms, saveForm, selectRoot, selectError, Errors, FormGrid } from 'react-formio';
import omit from 'lodash/omit';

import Loading from "../../containers/Loading";

class List extends Component {
  static propTypes = {
    forms: PropTypes.object.isRequired,
    onAction: PropTypes.func.isRequired,
    getForms: PropTypes.func.isRequired,
    errors: PropTypes.any,
  };

  UNSAFE_componentWillMount() {
    this.props.getForms(1);
  }

  render() {
    const { forms, onAction, getForms, errors } = this.props;

    if (forms.isActive) {
      return (
        <Loading />
      );
    }

    return (
      <div>
        <h1>Forms</h1>
        <Errors errors={errors} />
        <FormGrid
          forms={forms}
          onAction={onAction}
          getForms={getForms}
          operations={[
            {
              action: 'copy',
              buttonType: 'secondary',
              icon: 'copy',
              permissionsResolver() {
                return true;
              },
              title: 'Copy Form',
            },
            {
              action: 'edit',
              buttonType: 'secondary',
              icon: 'edit',
              permissionsResolver() {
                return true;
              },
              title: 'Edit Form',
            },
            {
              action: 'delete',
              buttonType: 'danger',
              icon: 'trash',
              permissionsResolver() {
                return true;
              },
            },
          ]}
        />
        <Link className="btn btn-primary" to="/form/create"><i className="fa fa-plus"></i> Create Form</Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forms: selectRoot('forms', state),
    errors: selectError('forms', state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForms: (page, query) => {
      dispatch(indexForms('forms', page, query));
    },
    onAction: (form, action) => {
      switch (action) {
        case 'view':
          dispatch(push(`/form/${form._id}`));
          break;
        case 'copy':
          const newForm = {
            ...omit(form, ['_id']),
            title: `${form.title} - Copy`,
            name: `${form.name}Copy`,
            path: `${form.path}-copy`,
            machineName: `${form.machineName}Copy`
          };
          dispatch(saveForm('form', newForm, (err, form) => {
            if (!err) {
              dispatch(push(`/form/${form._id}`));
            }
          }));
          break;
        case 'edit':
          dispatch(push(`/form/${form._id}/edit`));
          break;
        case 'delete':
          dispatch(push(`/form/${form._id}/delete`));
          break;
        default:
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(List);
