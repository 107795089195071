import AriesMultipleChoice from './AriesMultipleChoice/AriesMultipleChoice';
import AriesTextInputMixin from './AriesTextInput/AriesTextInput';
import CaliforniaHeader from './CaliforniaHeader/CaliforniaHeader';
import CaliforniaTable from './CaliforniaTable/CaliforniaTable';
import AriesFormHeader from './AriesFormHeader/AriesFormHeader';
import AriesSignature from './AriesSignature/AriesSignature';
import EditGrid from './EditGrid/EditGrid';
import CaliforniaTextBox from './CaliforniaTextBox/CaliforniaTextBox';
import CaliforniaSignatures from './CaliforniaSignatures/CaliforniaSignatures';
import PugContent from './PugContent/PugContent';

export default {
  arieseditgrid: EditGrid,
  ariesformheader: AriesFormHeader,
  ariesmultiplechoice: AriesMultipleChoice,
  ariesnumberinput: AriesTextInputMixin({ title: 'Aries Number Input', icon: 'calculator', componentType: 'ariesnumberinput', type: 'number' }),
  ariestextinput: AriesTextInputMixin({ title: 'Aries Text Input', icon: 'font', componentType: 'ariestextinput', type: 'text' }),
  ariesdateinput: AriesTextInputMixin({ title: 'Aries Date Input', icon: 'calendar', componentType: 'ariesdateinput', type: 'date' }),
  ariestimeinput: AriesTextInputMixin({ title: 'Aries Time Input', icon: 'hourglass', componentType: 'ariestimeinput', type: 'time' }),
  ariessignature: AriesSignature,
  californiaheader: CaliforniaHeader,
  californiasignatures: CaliforniaSignatures,
  californiatable: CaliforniaTable,
  californiatextbox: CaliforniaTextBox,
  pugcontent: PugContent,
};
