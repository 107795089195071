import React from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import PropTypes from "prop-types";
import Ajv from 'ajv';

import settingsForm from "./CaliforniaHeader.settingsForm";
import formSchema from "./CaliforniaHeader.schema.json";
import useFormioValue from "../common/useFormioValue";
import "./style.scss";

const CaliforniaHeader = ({ component, getValue }) => {
  const [raw] = useFormioValue(getValue);
  const value = raw?.value;

  React.useEffect(() => {
    if (value) {
      const ajv = new Ajv();
      const validate = ajv.compile(formSchema);
      const valid = validate(value);
      if (!valid) console.error(validate.errors);
    }
  }, [raw]);

  return (
    <table
      border="1"
      className={`california-table ${component.customClass}`}
      style={component.pageBreakBefore ? { breakBefore: 'page' } : {}}>
      <thead>
        <tr>
          <th style={{ width: "30%" }}>
            Wet Pipe
            <br />
            Fire Sprinkler System
          </th>
          <th colSpan="4" style={{ width: "40%" }}>
            California Code of Regulations - Title 19
            <br />
            Inspection, Testing, and Maintenance
          </th>
          <th style={{ width: "20%" }}>
            5-Year
            <br />
            Report
          </th>
          <th style={{ width: "10%" }}>
            {component.pageNo} of {component.pageCount}
          </th>
        </tr>
      </thead>

      <tbody>
        {component.compact ? (
          <>
            <tr>
              <td colSpan="2" className="text-center" style={{ width: "40%" }}>
                <strong>Property Information</strong>
              </td>
              <td rowSpan="5" className="text-center">
                <img
                  className="rounded"
                  height="128"
                  alt="state of california"
                  src="assets/state_of_california.png"
                />
              </td>
              <td colSpan="4" className="text-center" style={{ width: "40%" }}>
                <strong>Contractor or Licensed Owner Information</strong>
              </td>
            </tr>

            <tr>
              <td colSpan="2">&nbsp;</td>
              <td colSpan="4">&nbsp;</td>
            </tr>

            <tr>
              <td colSpan="2">Building Name {value?.propertyInformation?.buildingName}</td>
              <td colSpan="4">Name {value?.contractorInformation?.name}</td>
            </tr>

            <tr>
              <td colSpan="2">Address {value?.propertyInformation?.address}</td>
              <td colSpan="4">Job # {value?.contractorInformation?.jobNo}</td>
            </tr>

            <tr>
              <td colSpan="2">City {value?.propertyInformation?.city}</td>
              <td colSpan="4">&nbsp;</td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <td
                colSpan="2"
                className="text-center py-3"
                style={{ width: "40%" }}
              >
                <strong>Property Information</strong>
              </td>
              <td rowSpan="4" className="text-center">
                <img
                  className="rounded"
                  height="128"
                  alt="state of california"
                  src="assets/state_of_california.png"
                />
              </td>
              <td
                colSpan="4"
                className="text-center py-3"
                style={{ width: "40%" }}
              >
                <strong>Contractor or Licensed Owner Information</strong>
              </td>
            </tr>

            <tr>
              <td colSpan="2">Building Name</td>
              <td colSpan="4">Name {value?.contractorInformation?.name}</td>
            </tr>

            <tr>
              <td colSpan="2">{value?.propertyInformation?.buildingName}</td>
              <td colSpan="4">Address {value?.contractorInformation?.address}</td>
            </tr>

            <tr>
              <td colSpan="2">Address</td>
              <td colSpan="2" className="border-right-0">
                City {value?.contractorInformation?.city}
              </td>
              <td className="border-left-0 border-right-0">
                St. {value?.contractorInformation?.state}
              </td>
              <td className="border-left-0">Zip {value?.contractorInformation?.zip}</td>
            </tr>

            <tr>
              <td colSpan="2">{value?.propertyInformation?.address}</td>
              <td>License # {value?.licenseNo}</td>
              <td colSpan="4">Phone {value?.contractorInformation?.phone}</td>
            </tr>

            <tr>
              <td colSpan="2">City {value?.propertyInformation?.city}</td>
              <td>
                <input type="checkbox" checked={value?.sfm} /> SFM
              </td>
              <td colSpan="4">Job # {value?.contractorInformation?.jobNo}</td>
            </tr>

            <tr>
              <td colSpan="2">Contact Person {value?.propertyInformation?.contactPerson}</td>
              <td>
                <input type="checkbox" checked={value?.cslb} /> CSLB
              </td>
              <td colSpan="4">Misc. {value?.contractorInformation?.misc}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

CaliforniaHeader.propTypes = {
  component: PropTypes.shape({
    customClass: PropTypes.string,
    pageNo: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    compact: PropTypes.bool,
  }).isRequired,
  getValue: PropTypes.func,
};

export default class ReactCaliforniaHeader extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "California Header",
      icon: "table",
      group: "california",
      documentation: "",
      weight: -10,
      schema: ReactCaliforniaHeader.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "californiaheader",
      pageNo: 1,
      pageCount: 3,
      input: false,
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <CaliforniaHeader
        component={this.component} // These are the component settings if you want to use them to render the component.
        getValue={this.getValueAsync} // The starting value of the component.
      />,
      element
    );
  }

  /**
   * Delays the evaluation of this.dataValue because otherwise react cannot
   * get the latest value for the component.
   */
  getValueAsync = () =>
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.dataValue) {
          clearInterval(interval);
          resolve(this.dataValue)
        }
      }, 50);
    });

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
