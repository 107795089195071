import React from 'react';

const useNotes = (component, value, setValue) => {
  const hasNotes = React.useMemo(() => {
    return component.allowNotes && value?.hasNotes;
  }, [component, value]);

  const onToggleNotes = React.useCallback((e) => {
    setValue({ hasNotes: e.target.checked });
  }, [setValue]);

  const onChangeNotes = React.useCallback((e) => {
    setValue({ notes: e.target.value });
  }, [setValue]);

  return [hasNotes, onToggleNotes, onChangeNotes];
};


export default useNotes;
