import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { initAuth, Formio, Components, WebformBuilder } from 'react-formio';

import App from './App';
import { AppConfig } from './config';
import store, { history } from './store';

import { setupAriesComponents } from 'aries-formio-components';
import './styles.scss';

Formio.setProjectUrl(AppConfig.projectUrl);
Formio.setBaseUrl(AppConfig.apiUrl);
setupAriesComponents(Formio, Components, WebformBuilder);

// Initialize the current user
store.dispatch(initAuth());

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
