import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container text-center" style={{padding: '15px'}}>
        Built with ♥ by&nbsp;
        <a href="https://spacedev.uy">SpaceDev</a>
      </div>
    </footer>
  );
};

export default Footer;
