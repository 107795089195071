import React from 'react';
import PropTypes from 'prop-types';
import useFocus from '../useFocus';

const NotesInput = ({ visible, id, value, onChange }) => {
  const [focused, onFocus, onBlur] = useFocus();

  return visible ? (
    <>
      <label htmlFor={id} className={`${focused ? 'active-label' : ''} mt-3`}>Notes</label>
      <input type="text" className="form-control" id={id} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} />
    </>
  ) : null;
};

NotesInput.propTypes = {
  visible: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

NotesInput.defaultProps = {
  visible: false,
};

export default NotesInput;
