import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const NotesToggle = ({ id, name, label, visible, className, checked, onChange }) => {
  return visible ? (
    <div className={`form-check notes ${className}`}>
      <label htmlFor={id} className="form-check-label">
        &nbsp;&nbsp;{label}
        <input type="checkbox" id={id} name={name} checked={checked} onChange={onChange} />
        <span className="checkmark form-check-input notes-icon"></span>
      </label>
    </div>
  ) : null;
};

NotesToggle.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  visible: PropTypes.bool,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default NotesToggle;
