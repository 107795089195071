import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from 'react-formio';
import PropTypes from 'prop-types';

import settingsForm from './AriesTextInput.settingsForm';
import Checkbox from '../common/Checkbox';
import NotesInput from '../common/NotesInput';
import NotesToggle from '../common/NotesToggle';
import useFocus from '../common/useFocus';
import useFormioValue from '../common/useFormioValue';
import useNotes from '../common/useNotes';
import './style.scss';
import useHide from "../common/useHide";

const AriesTextInput = ({ component, getValue, onChange, type }) => {
  const [value, setValue] = useFormioValue(getValue, onChange);
  const [focused, onFocus, onBlur] = useFocus();
  const [isHidden, onToggleHidden] = useHide(component, value, setValue);
  const [hasNotes, onToggleNotes, onChangeNotes] = useNotes(component, value, setValue);

  const onValueChange = React.useCallback((e) => {
    setValue({ value: e.target.value });
  }, [setValue]);

  return (
    <div className="col border border-dark py-3 px-4 aries-form-control">
      <div className="d-flex flex-row justify-content-between mb-3">
        <label htmlFor={`input-${component.key}`} className={focused ? 'active-label' : ''}>{component.label}</label>
        <div className="d-flex flex-row justify-content-start">
          <NotesToggle
            id={`notes-${component.key}`}
            name="notes"
            label="Notes"
            visible={component.allowNotes}
            className="mr-3"
            checked={hasNotes}
            onChange={onToggleNotes}
          />
          {
            component.canHide &&
            <Checkbox
              id={`hide-${component.key}`}
              name="hide"
              label="Hide"
              checked={isHidden}
              onChange={onToggleHidden}
            />
          }
        </div>
      </div>

      <div className="form-group">
        <input type={type} className="form-control" id={`input-${component.key}`} value={value?.value ?? ''} onChange={onValueChange} onFocus={onFocus} onBlur={onBlur} />

        <NotesInput
          visible={hasNotes}
          id={`notes-${component.key}`}
          value={value?.notes ?? ''}
          onChange={onChangeNotes}
        />
      </div>
    </div>
  );
};

AriesTextInput.propTypes = {
  component: PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string.isRequired,
    canHide: PropTypes.bool,
    allowNotes: PropTypes.bool,
  }).isRequired,
  getValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ({ title, icon, componentType, type }) => class ReactAriesTextInput extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title,
      icon,
      group: 'ariesForms',
      documentation: '',
      weight: -10,
      schema: ReactAriesTextInput.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: componentType,
      hideLabel: true,
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <AriesTextInput
        component={this.component} // These are the component settings if you want to use them to render the component.
        getValue={this.getValueAsync} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        type={type}
      />,
      element
    );
  }

  /**
   * Delays the evaluation of this.dataValue because otherwise react cannot
   * get the latest value for the component.
   */
  getValueAsync = () =>
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.dataValue) {
          clearInterval(interval);
          resolve(this.dataValue)
        }
      }, 50);
    });

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
};
