import components from "./components";

export const setupAriesComponents = (Formio, Components, WebformBuilder) => {
  console.log("Aries components setup");
  Components.setComponents(components);

  const originalDownloadFile = Formio.prototype.downloadFile;
  Formio.prototype.downloadFile = async function (file, options) {
    const ret = await originalDownloadFile.call(this, file, options);
    return {
      ...ret,
      url: `https://aries-report-attachments.s3.amazonaws.com/${ret.url}`,
    };
  };

  // Define groups
  Object.defineProperty(WebformBuilder.prototype, "defaultGroups", {
    get: () => ({
      ariesForms: {
        title: "Aries forms",
        weight: 0,
        default: true,
      },
      california: {
        title: "California",
        weight: 10,
      },
      dangerzone: {
        title: "Danger Zone",
        weight: 20,
      },
      premium: {
        title: "Premium",
        weight: 70,
      },
    }),
  });
};
