import baseEditForm from 'formiojs/components/_classes/component/Component.form';
import {conditional, data, display, ignoreSections, validation} from '../common/base.settingsForm';

export default (...extend) => {
  return baseEditForm([
    {
      key: 'display',
      components: [
        ...display.filter(ctrl => ctrl.key !== 'label'),
        {
          key: 'label',
          type: 'textfield',
          input: true,
          label: 'Label',
        },
        {
          type: 'textfield',
          input: true,
          key: 'customClass',
          label: 'Custom CSS Class',
          placeholder: 'Custom CSS Class',
          tooltip: 'Custom CSS class to add to this component.',
        },
        {
          key: 'canHide',
          input: true,
          type: 'checkbox',
          label: 'Allow this question to be hidden in the resulting report',
        },
      ],
    },
    {
      key: 'data',
      components: [
        ...data,
        {
          type: 'textfield',
          label: 'Default Value',
          key: 'defaultValue.value',
          weight: 5,
          placeholder: 'Default Value',
          tooltip: 'The will be the value for this field, before user interaction. Having a default value will override the placeholder text.',
          input: true,
        },
      ],
    },
    {
      key: 'conditional',
      components: conditional,
    },
    {
      key: 'validation',
      components: validation,
    },
    ignoreSections.ignoreLayout,
    ignoreSections.ignoreLogic,
  ], ...extend);
};
