import React from 'react';

const useDeficiency = (component, value, setValue) => {
  const isDeficiency = React.useMemo(
    () => component.values
      .find(({ value: val }) => val === value?.value)?.generatesDeficiency ?? false,
    [component, value],
  );

  const onChangeDeficiencyNote = React.useCallback((e) => {
    setValue({ deficiencyNote: e.target.value });
  }, [setValue]);

  return [isDeficiency, onChangeDeficiencyNote];
};

export default useDeficiency;
