import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Checkbox = ({ id, name, label, checked, onChange }) => {
  return (
    <div className="form-check">
      <label htmlFor={id} className="form-check-label">
        &nbsp;&nbsp;{label}
        <input type="checkbox" id={id} name={name} checked={checked} onChange={onChange} />
        <span className="checkmark form-check-input"></span>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
