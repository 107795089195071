import baseEditForm from 'formiojs/components/_classes/component/Component.form';
import { data, display, conditional, validation, ignoreSections } from '../common/base.settingsForm';

export default (...extend) => {
  return baseEditForm([
    {
      key: 'display',
      components: [
        ...display.filter(ctrl => ctrl.key !== 'label'),
        {
          key: 'label',
          type: 'textfield',
          input: true,
          label: 'Label',
        },
        {
          type: 'textfield',
          input: true,
          key: 'customClass',
          label: 'Custom CSS Class',
          placeholder: 'Custom CSS Class',
          tooltip: 'Custom CSS class to add to this component.',
        },
        {
          key: 'canHide',
          input: true,
          type: 'checkbox',
          label: 'Allow this question to be hidden in the resulting report',
        },
      ],
    },
    {
      key: 'data',
      components: [
        ...data,
        {
          key: 'allowNotes',
          label: 'Allows notes',
          type: 'checkbox',
          input: true,
        },
      ],
    },
    {
      key: 'conditional',
      components: conditional,
    },
    {
      key: 'validation',
      components: validation
    },
    ignoreSections.ignoreLayout,
    ignoreSections.ignoreLogic,
  ], ...extend);
};
