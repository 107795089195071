import baseEditForm from 'formiojs/components/_classes/component/Component.form';
import {display, ignoreSections} from '../common/base.settingsForm';

export default (...extend) => {
  return baseEditForm([
    {
      key: 'display',
      components: [
        ...display,
        {
          type: 'textfield',
          input: true,
          key: 'customClass',
          label: 'Custom CSS Class',
          placeholder: 'Custom CSS Class',
          tooltip: 'Custom CSS class to add to this component.',
        },
      ],
    },
    ignoreSections.ignoreData,
    ignoreSections.ignoreConditional,
    ignoreSections.ignoreLayout,
    ignoreSections.ignoreLogic,
    ignoreSections.ignoreValidation,
  ], ...extend);
};
