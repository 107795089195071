import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-formio';
import { AppConfig, AuthConfig } from "../../config";
import { setUser } from "react-formio";
import {push} from "connected-react-router";

const Register = (props) => {
  return (
    <Form {...props} />
  );
};

const mapStateToProps = () => {
  return {
    src: AppConfig.projectUrl + '/' + AuthConfig.register.form,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitDone: (submission) => {
      dispatch(push(AuthConfig.authState));
      dispatch(setUser(submission));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
