import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

const InputFormPropertiesEditor = (props) => {
  const { handleChange, form } = props;
  const [customerEmail, setCustomerEmail] = useState('');

  const onCommitEmail = useCallback((event) => {
    switch (event.key) {
      case "Enter":
        if (!EMAIL_REGEX.test(event.target.value)) {
          alert('Invalid email address');
          return;
        }
        setCustomerEmail('');
        handleChange(
          'ariesCustomerEmails',
          {
            ...event,
            target: {
              ...event.target,
              value: [event.target.value, ...form.ariesCustomerEmails],
            },
          },
        );
        break;
      default:
        break;
    }
  }, [form, handleChange]);

  if (form?.ariesType === 'pdf') {
    return null;
  }

  return (
    <div className="row">
      <div className="col-lg-2 col-md-4 col-sm-4 pb-2">
        <div id="form-group-title" className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="customerFilterEnabled"
            checked={form.customerFilterEnabled}
            onChange={event => handleChange('customerFilterEnabled', event)}
          />
          <label htmlFor="customerFilterEnabled" className="form-check-label field-required">Enable customer filter</label>
        </div>
      </div>
      {form.customerFilterEnabled ? (
        <>
          <div className="col-lg-2 col-md-4 col-sm-4">
            <div id="form-group-title" className="form-group">
              <label htmlFor="customerEmail" className="control-label field-required">Customer email</label>
              <input
                type="email"
                className="form-control"
                id="customerEmail"
                value={customerEmail}
                onChange={event => setCustomerEmail(event.target.value)}
                onKeyDown={onCommitEmail}
              />
            </div>
          </div>
          <div className="col-lg-8 col-md-4 col-sm-4">
            <span>{form.ariesCustomerEmails?.join(', ')}</span>
          </div>
        </>
      ) : null}
    </div>
  );
};

InputFormPropertiesEditor.propTypes = {
  form: PropTypes.object.isRequired,
  pdfTemplates: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
  handleChange: PropTypes.func,
  saveForm: PropTypes.func,
};

InputFormPropertiesEditor.defaultProps = {
  form: {
    title: '',
    name: '',
    path: '',
    display: 'form',
    type: 'form',
    components: [],
    ariesType: 'form',
  },
  pdfTemplates: [],
};

export default InputFormPropertiesEditor;
