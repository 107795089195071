import { Formio } from 'formiojs';
import _ from 'lodash';

export default [
  {
    type: 'select',
    input: true,
    key: 'storage',
    label: 'Storage',
    placeholder: 'Select your file storage provider',
    weight: 0,
    tooltip: 'Which storage to save the files in.',
    valueProperty: 'value',
    dataSrc: 'custom',
    data: {
      custom() {
        return _.map(Formio.Providers.getProviders('storage'), (storage, key) => ({
          label: storage.title,
          value: key
        }));
      }
    }
  },
  {
    type: 'textfield',
    input: true,
    key: 'url',
    label: 'Url',
    weight: 10,
    placeholder: 'Enter the url to post the files to.',
    tooltip: "See <a href='https://github.com/danialfarid/ng-file-upload#server-side' target='_blank'>https://github.com/danialfarid/ng-file-upload#server-side</a> for how to set up the server.",
    conditional: {
      json: { '===': [{ var: 'data.storage' }, 'url'] }
    }
  },
  {
    type: 'textfield',
    input: true,
    key: 'options.indexeddb',
    label: 'Database',
    weight: 10,
    placeholder: 'Enter the indexeddb database name',
    conditional: {
      json: {
        in: [
          {
            var: 'data.storage'
          },
          ['indexeddb']
        ],
      }
    }
  },
  {
    type: 'textfield',
    input: true,
    label: 'Table',
    key: 'options.indexeddbTable',
    weight: 10,
    placeholder: 'Enter the name for indexeddb table',
    conditional: {
      json: {
        in: [
          {
            var: 'data.storage'
          },
          ['indexeddb']
        ],
      }
    }
  },
  {
    type: 'textarea',
    key: 'options',
    label: 'Custom request options',
    tooltip: 'Pass your custom xhr options(optional)',
    rows: 5,
    editor: 'ace',
    input: true,
    weight: 15,
    placeholder: `{
  "withCredentials": true
}`,
    conditional: {
      json: {
        '===': [{
          var: 'data.storage'
        }, 'url']
      }
    }
  },
  {
    type: 'textfield',
    input: true,
    key: 'fileKey',
    label: 'File form-data key',
    weight: 17,
    placeholder: 'Enter the key name of a file for form data.',
    tooltip: 'Key name that you would like to modify for the file while calling API request.',
    conditional: {
      json: {
        '===': [{
          var: 'data.storage'
        }, 'url']
      }
    }
  },
  {
    type: 'textfield',
    input: true,
    key: 'dir',
    label: 'Directory',
    placeholder: '(optional) Enter a directory for the files',
    tooltip: 'This will place all the files uploaded in this field in the directory',
    weight: 20
  },
  {
    type: 'textfield',
    input: true,
    key: 'fileNameTemplate',
    label: 'File Name Template',
    placeholder: '(optional) {{{name}}-{{guid}}}}}',
    tooltip: 'Specify template for name of uploaded file(s). Regular template variables are available (`data`, `component`, `user`, `value`, `moment` etc.), also `fileName`, `guid` variables are available. `guid` part must be present, if not found in template, will be added at the end.',
    weight: 25
  },
];
