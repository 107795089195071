import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const RadioButton = ({ id, name, label, value, checked, onChange }) => {
  return (
    <div className="form-check">
      <label htmlFor={id} className="form-check-label">
        &nbsp;&nbsp;{label}
        <input type="radio" id={id} name={name} value={value} onChange={onChange} checked={checked} />
        <span className="radio form-check-input" />
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioButton;
