import baseEditForm from 'formiojs/components/_classes/component/Component.form';
import {display, ignoreSections} from '../common/base.settingsForm';

export default (...extend) => {
  return baseEditForm([
    {
      key: 'display',
      components: [
        ...display.filter(ctrl => ctrl.key !== 'label'),
        {
          key: 'label',
          ignore: true,
        },
        {
          key: 'customClass',
          ignore: true
        },
        {
          type: 'textarea',
          input: true,
          editor: 'ace',
          rows: 10,
          as: 'pug',
          label: 'Content',
          tooltip: 'The content of this pug element.',
          defaultValue: 'p #{name.value}\'s Pug source code!',
          key: 'pugTemplate',
        },
      ],
    },
    ignoreSections.ignoreData,
    ignoreSections.ignoreConditional,
    ignoreSections.ignoreValidation,
    ignoreSections.ignoreLayout,
    ignoreSections.ignoreLogic,
  ], ...extend);
};
