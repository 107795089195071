import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveForm, selectForm, FormBuilder, Errors, selectError } from 'react-formio';
import FormPropertiesEditor, { useFormProperties } from '../../../containers/FormPropertiesEditor';


const Edit = props => {
  const {
    handleChange,
    saveForm,
    formChange,
    form,
    pdfTemplates,
  } = useFormProperties(props);

  const { options, builder } = props;

  return (
    <div>
      <h2>Edit {form.title} Form</h2>
      <hr />
      <Errors errors={props.errors} />
      <div>
        <FormPropertiesEditor
          {...props}
          form={form}
          pdfTemplates={pdfTemplates}
          handleChange={handleChange}
          saveForm={saveForm}
        />
        <FormBuilder
          key={props.form._id}
          form={form}
          options={options}
          builder={builder}
          onChange={formChange}
        />
      </div>
    </div>
  );
};

Edit.propTypes = {
  form: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  errors: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    form: selectForm('form', state),
    saveText: 'Save Form',
    errors: selectError('form', state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveForm: (form) => dispatch(saveForm('form', form, (err) => {
      if (!err) {
        alert('Saved');
      }
    })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);
