import OriginalEditGrid from 'formiojs/components/editgrid/EditGrid';
import settingsForm from './EditGrid.settingsForm';

export default class EditGrid extends OriginalEditGrid {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
   static get builderInfo() {
    return {
      title: 'Edit Grid',
      icon: 'tasks',
      group: 'ariesForms',
      documentation: '/userguide/#editgrid',
      weight: 30,
      schema: EditGrid.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema(...extend) {
    return OriginalEditGrid.schema({
      type: 'arieseditgrid',
      hideLabel: true,
      templates: {
        header: EditGrid.defaultHeaderTemplate,
        row: EditGrid.defaultRowTemplate,
        footer: '',
      },
    }, ...extend);
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  static get defaultHeaderTemplate() {
    return `<div class="row">
      {% util.eachComponent(components, function(component) { %}
          <div class="col-sm-2">{{ component.label }}</div>
      {% }) %}
    </div>`;
  }

  static get defaultRowTemplate() {
    return `<div class="row">
      {% util.eachComponent(components, function(component) { %}
          <div class="col-sm-2">
            {{ getView(component, row[component.key]?.value ?? row[component.key]) }}
          </div>
      {% }) %}
      {% if (!instance.disabled) { %}
        <div class="col-sm-2">
          <div class="btn-group pull-right">
            <button class="btn btn-default btn-light btn-sm editRow">Edit</button>
            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}
              <button class="btn btn-danger btn-sm removeRow">Remove</button>
            {% } %}
          </div>
        </div>
      {% } %}
    </div>`;
  }
}
