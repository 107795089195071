const API_URL = 'https://reportbuilder.formio.firelabinc.com';

let query = {};
window.location.search.substr(1).split('&').forEach(function(item) {
  query[item.split('=')[0]] = item.split('=')[1] && decodeURIComponent(item.split('=')[1]);
});

export const AppConfig = {
  projectUrl: API_URL,
  apiUrl: API_URL,
};

export const AuthConfig = {
  anonState: '/auth',
  authState: '/form',
  login: {
    form: 'user/login',
  },
  register: {
    form: 'user/register',
  },
};
