import React from 'react';

const useHide = (component, value, setValue) => {
  const isHidden = React.useMemo(() => {
    return component.canHide && value?.isHidden;
  }, [component, value]);

  const onToggleHidden = React.useCallback((e) => {
    setValue({ isHidden: e.target.checked });
  }, [setValue]);

  return [isHidden, onToggleHidden];
};

export default useHide;
